@media (max-width: 767px) {
  .MuiInputLabel-formControl {
    font-size: 0.8rem !important;
  }

  .MuiInputLabel-shrink {
    transform: translate(0, 1.5px) scale(1) !important;
    font-size: 0.8rem !important;
  }
}

@media (max-width: 399px) {
  .MuiInputLabel-formControl {
    font-size: 0.7rem !important;
  }

  .MuiInputLabel-shrink {
    transform: translate(0, 1.5px) scale(1) !important;
    font-size: 0.7rem !important;
  }
}

@media (max-width: 339px) {
  .MuiInputLabel-formControl {
    font-size: 0.65rem !important;
  }

  .MuiInputLabel-shrink {
    transform: translate(0, 1.5px) scale(1) !important;
    font-size: 0.65rem !important;
  }
}
