@import './legend.css';
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: 'Rubik';
    overflow-y: scroll;
}

a:not(.externalLink) {
    text-decoration: none;
    color: unset;
}

h1,
h2,
h5 {
    color: #333333;
    font-weight: bolder;
}

h3,
h4 {
    font-weight: 400;
}

h3 {
    color: #333333;
    font-size: 28px;
}

h4 {
    color: #333333;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
}

p {
    color: #333333;
}

b {
    font-weight: 600;
}

a.--active {
    color: var(--orange);
}
