:root {
    --blueBright: #3d51b5;
    --blueGray: #7590b2;
    --orange: #f3705b;
    --bright-orange: #f59b19;
    --yellow: #ffd705;
    --lightBlue: #e6f1ff;
    --green: #36ea33;
    --errorRed: #f44336;
    --disabledBackground: rgb(211, 211, 211);
    --disabledColor: rgb(102, 102, 102);
    --grayText: rgb(153, 153, 153);
    --darkText: #1A1C1C;
    --lightGreen: #e3f0f2;
    --deepGreen: #a7ced3;
    --deepWhite: #fafafa;
}
